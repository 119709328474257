<template>
  <!-- 模拟面试个人简介 -->
  <div id="information" class="form">
    <el-form :model="form" label-width="120px" :rules="rules" ref="form">
      <el-form-item label="nickName：" prop="nickName">
        <el-input v-model="form.nickName"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="skype用户名" prop="skypeUserName">
        <el-input v-model="form.skypeUserName"></el-input>
      </el-form-item>
      <el-form-item label="skype邮箱" prop="skypeEmail">
        <el-input v-model="form.skypeEmail"></el-input>
      </el-form-item>
      <el-form-item label="本人手持工牌照" prop="workCard">
        <el-upload
          ref="upload"
          :on-success="handleSuccess"
          :limit="1"
          :before-upload="beforeUpload"
          :on-remove="removeFile"
          :action="uploadUrl"
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          style="display: inline-block; width: 100%"
          :data="{ isPublic: false }"
          :headers="{ 'X-Access-Token': token }"
          :show-file-list="false"
        >
          <el-button icon="el-icon-paperclip" size="small" slot="trigger"
            >上传照片
          </el-button>
          <span
            style="vertical-align: top; margin-left: 12px; cursor: pointer"
            @click="showViewer = true"
          >
            <i class="el-icon-info"></i>
            查看范例</span
          >
          <br />
          <img
            v-if="cover && cover != ''"
            :src="cover"
            alt=""
            style="max-width: 300px"
          />
        </el-upload>
        <el-image-viewer
          v-if="showViewer"
          :z-index="9999"
          :url-list="[require('@/assets/refer/example.png')]"
          :on-close="closeViewer"
        />
      </el-form-item>
      <div style="text-align: center">
        <el-button type="primary" @click="submitForm">提交资料</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getMockInterviewer, saveMockInterviewer } from "@/service/mock";
import _ from "lodash";
export default {
  name: "information",
  components: { ElImageViewer },
  data() {
    const workCardValidator = (rule, value, callback) => {
      if (!this.form.workCard || this.form.workCard == "") {
        callback(new Error("请上传本人手持工牌照!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        email: "",
      },
      cover: "",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      isAccess: false,
      countDown: 60,
      sendInterval: null,
      isSend: false,
      showViewer: false,
      companys: [],
      timeout: null,
      searchCompanyKey: "",
      schools: [],
      rules: {
        nickName: [
          {
            required: true,
            message: "请输入nickName",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        skypeUserName: [
          {
            required: true,
            message: "请输入skype用户名",
            trigger: "blur",
          },
        ],
        skypeEmail: [
          {
            required: true,
            message: "请输入skype邮箱",
            trigger: "blur",
          },
        ],
        workCard: [
          {
            required: true,
            validator: workCardValidator,
            trigger: "change",
          },
        ],
      },
      originData: {},
    };
  },
  computed: {
    windowHeight() {
      return window.innerHeight + "px";
    },
  },
  mounted() {
    this.getMockInterviewer();
  },
  methods: {
    handleSuccess(res, file) {
      this.cover = URL.createObjectURL(file.raw);
      this.form.workCard = res.result.objectKey;
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    removeFile() {
      this.form.workCard = "";
      this.cover = "";
    },
    addCompany() {
      this.form.originalCompany.push("");
    },
    sendVerify() {
      //ajax successed after
      if (!this.form.email || this.form.email == "") {
        return this.$message.error("请输入企业邮箱！");
      }
      sendVerifyEmail(this.form.email).then((res) => {
        if (res.success) {
          this.$message.success("验证码已发送，请注意查收");
          this.isSend = true;
          this.sendInterval = setInterval(() => {
            this.countDown = this.countDown - 1;
            if (this.countDown == 0) {
              this.isSend = false;
              this.countDown = 60;
              clearInterval(this.sendInterval);
            }
          }, 1000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeViewer() {
      this.showViewer = false;
    },
    getMockInterviewer() {
      getMockInterviewer().then((res) => {
        if (res.success) {
          if (res.result) {
            this.form = res.result;
            this.cover = res.result.workCard;
          }
        } else {
          this.$message.warning(res.message);
          this.$router.push("/");
        }
      });
    },
    submitForm() {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveMockInterviewer(this.form).then((res) => {
            if (res.success) {
              this.$message.success("修改成功！");
              setTimeout(() => {
                location.reload();
              }, 1500);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  min-height: 100%;
  padding: 50px 160px;
  border-radius: 8px;
  background: #fff;
  .relativeInput {
    position: relative;
    margin-bottom: 6px;
  }
  .absoluteIcon {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
}
</style>